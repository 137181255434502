<template>
  <div>
    <QueryForm
      v-model="queryForm"
      show-export
      :query-btn-loading="tableLoading"
      :rules="rules"
      @handleExport="handleExport"
      @query="handleQuery"
      @reset="handleQueryFormReset"
    >
      <el-form-item label="月份" prop="yearMonth">
        <el-date-picker
          v-model="queryForm.yearMonth"
          value-format="yyyyMM"
          type="month"
          :clearable="false"
        />
      </el-form-item>

      <el-form-item label="备货渠道">
        <Select
          v-model="queryForm.stockChannelIdList"
          api-key="StockChannelList"
          clearable
          :configuration=" {
            key: 'channelId',
            label: 'channelName',
            value: 'channelId'
          }"
          multiple
        />

      </el-form-item>

      <el-form-item label="品牌">
        <Select
          v-model="queryForm.brandIdList"
          api-key="brandList"
          clearable
          :configuration=" {
            key: 'id',
            label: 'brandName',
            value: 'id'
          }"
          multiple
        />
      </el-form-item>

      <el-form-item label="BG">
        <Select
          v-model="queryForm.bgIdList"
          api-key="brandList"
          clearable
          multiple
          :configuration=" {
            key: 'id',
            label: 'brandName',
            value: 'id'
          }"
        />
      </el-form-item>
      <el-form-item label="BU">
        <Select
          v-model="queryForm.buIdList"
          api-key="buList"
          clearable
          multiple
          :configuration="{
            key: 'id',
            label: 'buName',
            value: 'id'
          }"
        />
      </el-form-item>
      <el-form-item label="产品定位">
        <Select
          v-model="queryForm.positionIdList"
          style="width:100%;"
          api-key="position"
          :configuration="{
            key: 'stylePositionId',
            label: 'position',
            value: 'stylePositionId'
          }"
          clearable
          filterable
          collapse-tags
          multiple
        />
      </el-form-item>
      <el-form-item label="SKU">
        <el-input v-model="queryForm.sku" :placeholder="$t('page.inputPlaceholder')" />
      </el-form-item>
      <el-form-item label="Style">
        <Select
          v-model="queryForm.styleId"
          api-key="styleList"
          clearable
          @change="handleChangeStyle"
        />
      </el-form-item>
      <el-form-item label="Color">
        <Select
          v-model="queryForm.color"
          api-key="colorList"
          :params="{ styleId : queryForm.styleId }"
          ganged
          :configuration=" { key: 'id', label: 'colorName', value: 'colorName' }"
          not-required-params
        />
      </el-form-item>
      <el-form-item label="Size">
        <Select
          v-model="queryForm.size"
          not-required-params
          api-key="sizeList"
          :params="{ styleId : queryForm.styleId}"
          ganged
          :configuration=" { key: 'id', label: 'size', value: 'size' }"
        />
      </el-form-item>
      <el-form-item label="新老品标识">
        <Select
          v-model="queryForm.symbolDictList"
          :select-options="_getAllCommodityDict('SCM_PRODUCT_SYMBOL')"
          :configuration=" { key: 'val', label: 'label', value: 'val' }"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="产品类型标识">
        <Select
          v-model="queryForm.productTypeDictList"
          :select-options="_getAllCommodityDict('SCM_PRODUCT_TYPE')"
          :configuration=" { key: 'val', label: 'label', value: 'val' }"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="统计方式">
        <el-radio-group v-model="queryForm.statisticsMethodDict">
          <el-radio label="style">Style</el-radio>
          <el-radio label="sku">Sku</el-radio>
        </el-radio-group>
      </el-form-item>
    </QueryForm>

    <Table
      :table-data="tableData"
      max-height="600px"
      :columns="columns.SalesPlanStatisticsTableColumns"
      :loading="tableLoading"
    />
    <Paging :pager="pager" end class="mt-3" @pagination="pagerUpdate" />

  </div>
</template>

<script>
import QueryForm from '@/components/QueryForm'
import { accuracyStatisticsSkuPageList, accuracyStatisticsStylePageList, inventoryVersion } from '@/api/configuration'
import Table from '@/components/Table'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import Paging from '@/components/Pagination'
import columns from '@/constant/tablecolumns'

export default {
  components: { QueryForm, Table, Select, Paging },
  mixins: [commodityInfoDict],
  data() {
    return {

      rules: {
        yearMonth: { required: true, message: '必填' }
      },
      columns,
      tableData: [],
      queryForm: {
        styleId: '',
        color: '',
        size: '',
        yearMonth: '',
        statisticsMethodDict: 'style'

      },
      tableLoading: false,
      pager: {
        size: 10,
        current: 1
      },
      dsbVersionOptions: []
    }
  },

  watch: {
    dsbVersionOptions(value) {
      if (value && value.length && this.queryForm.yearMonth) {
        this.handleQuery()
      }
    }
  },

  mounted() {
    this._getDsbVersionOptions()
  },
  methods: {
    handleInvDate(date) {
      const year = date.slice(0, 4)
      const month = date.slice(5)
      // 添加横线和日期
      return year + month
    },
    async _getDsbVersionOptions() {
      const { datas } = await inventoryVersion()
      this.dsbVersionOptions = datas
      this.queryForm.yearMonth = this.handleInvDate(datas[0])
    },
    async handleExport() {

    },

    pagerUpdate(val) {
      this.pager = val
      this.handleClick()
    },
    handleChangeStyle() {
      this.queryForm.color = ''
      this.queryForm.size = ''
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.queryForm.yearMonth = this.handleInvDate(this.dsbVersionOptions[0])
      this.handleQuery()
    },
    handleQuery() {
      this.pager.current = 1
      this.handleClick()
    },
    async handleClick() {
      try {
        this.tableLoading = true
        const func = this.queryForm.statisticsMethodDict === 'sku' ? accuracyStatisticsSkuPageList : accuracyStatisticsStylePageList
        const { datas: { records = [], current, size, total }} = await func(this.pager, this.queryForm)
        this.tableData = records
        this.pager = { current, size, total }
      } finally {
        this.tableLoading = false
      }
    }

  }
}
</script>

<style>

</style>
